<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('measure.bm.overview')">
    <CCol cols="12" lg="12">
      <CCard class="mb-0">
        <CCardHeader>
          <CRow>
            <CCol cols="4" xl="4" class="pt-0 pb-0 text-left">
              {{ $t('sq.burning_matters') }}
            </CCol>
            <CCol cols="8" xl="8" class="pt-0 pb-0 text-xl-right">
              <div v-if="checkPermission('measure.bm.addedit')" class="mt-1 mt-xl-0 d-inline-block align-top">
                <CButton class="m-0 link" color="primary" @click="openSidebarRight('burning_matter_details', { bm_question_id_external:  null });">
                  <i class="fas fa-plus mr-1"/>
                  <span>{{ $t('sq.Add_burning_matter') }}</span>
                </CButton>                
              </div>               
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody class="p-0">
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <b-table ref="burningMatterTable"
                       class="data_table"
                       :data="burningMatters"
                       :striped="true"
                       :hoverable="true"
                       :mobile-cards="true"
                       :paginated="isPaginated"
                       :per-page="perPage"
                       :current-page.sync="currentPage"
                       :pagination-simple="isPaginationSimple"
                       :pagination-position="paginationPosition"
                       :total="totalItems"
                       backend-pagination
                       @page-change="onPageChange"                       >
                
                <template slot-scope="props">
                  <b-table-column field="question" :label="$t('sq.burning_matter')" width="50%">
                    <span>{{props.row.question}}</span>
                    <div class="item_tags d-flex flex-row">
                      <b-tag v-if="props.row.subtopic" class="mt-1 detail">{{ props.row.subtopic.label }}</b-tag>
                    </div>
                  </b-table-column>
                  <b-table-column field="target_groups" :label="$t('common.Specified_for_groups')" width="15%">
                    <targetGroupPopover v-if="props.row.target_groups.length > 1 || (props.row.target_groups[0] && props.row.target_groups[0].default_company_group === 'N')"
                                        targetGroupsType="measure burning matter"
                                        :targetGroupsIdExternal="props.row.bm_question_id_external"
                                        popoverPlacement="left">
                    </targetGroupPopover>
                  </b-table-column>                  
                  <b-table-column field="start_date" :label="$t('sq.Measured_from')" width="10%">
                    <span v-if="props.row.start_date !== null">{{props.row.start_date | moment("DD-MM-YYYY")}}</span>
                    <span v-else>-</span>
                  </b-table-column>
                  <b-table-column field="end_date" :label="$t('sq.Measured_till')" width="10%">
                    <span v-if="props.row.end_date !== null">{{props.row.end_date | moment("DD-MM-YYYY")}}</span>
                    <span v-else>-</span>
                  </b-table-column>
                  <b-table-column field="headcount.headcount_total" label="">
                    <span class="d-flex pointer" @click="openSidebarRight('headcount_details', { headcount_type: 'burning matter', headcount_id_external: props.row.bm_question_id_external });">
                      <i class="icon top far fa-user mr-1"/>{{props.row.headcount.headcount_total}}
                    </span>
                  </b-table-column>
                  <b-table-column field="actions">
                    <div class="d-flex justify-content-lg-end">
                      <CDropdown v-if="checkPermission('measure.bm.overview') || checkPermission('measure.bm.insights')"
                                 color="primary"
                                 toggler-text=""
                                 :caret="false"
                                 class="ml-2 table_actions_dropdown">
                        <div slot="toggler-content">
                          <span class="d-flex align-items-center">
                            {{$t('common.Actions')}}<i class="fas fa-caret-down ml-1"></i>
                          </span>
                        </div>

                        <CDropdownItem v-if="checkPermission('measure.bm.insights')">
                          <div class="d-flex align-items-center" @click="showBurningMatterInsights( props.row.bm_question_id_external )">
                            <div class="post_action_icon text-center mr-1">
                              <i class="far fa-chart-bar"/>
                            </div>
                            <div class="flex-grow-1">
                              <span>{{$t('common.Show_insights')}}</span>
                            </div>
                          </div>
                        </CDropdownItem>
                        <CDropdownItem v-if="checkPermission('measure.bm.overview')">
                          <div class="d-flex align-items-center" @click="openSidebarRight('burning_matter_details', { bm_question_id_external:  props.row.bm_question_id_external });">
                            <div class="post_action_icon text-center mr-1">
                              <i class="fas fa-chevron-right"/>
                            </div>
                            <div class="flex-grow-1">
                              <span>{{$t('common.Show_details')}}</span>
                            </div>
                          </div>
                        </CDropdownItem>
                      </CDropdown>                    
                    </div>
                  </b-table-column>
                </template>
                <template slot="empty">
                  <div class="p-2 text-center">
                    <loadingSpinner mode="auto" v-if="!overviewDataLoaded" :content="$t('sq.Loading_burning_matters')"/>
                    <span v-else>{{$t('sq.No_burning_matters_found')}}</span>
                  </div>                
                </template>
              </b-table>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue'
import noPermission from '@/components/common/noPermission.vue';
import targetGroupPopover from '@/components/common/targetGroupPopover.vue'

export default {
  name: 'BurningMatters',
  components: {
    loadingSpinner,
    noPermission,
    targetGroupPopover
  },
  data() {
    return {
      platformPermissions: [],
      platformPermissionsLoaded: false,      
      burningMatters: [],
      overviewDataLoaded: false,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      burningMatterStartIndex: 0,
    }
  },
  methods: {
    getBurningMatters() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/measure/burning_matters/' + this.burningMatterStartIndex)
      .then(res => {
        // Clear the burningMatters array
        this.burningMatters = [];
        // Set the default currentTotal value
        let currentTotal = res.data.data.total_burning_matters;
        // Update the currentTotal value
        if(currentTotal / this.perPage > 1000) currentTotal = this.perPage * 1000;
        // Update the totalItems value
        this.totalItems = currentTotal;
        // Add the burning_matters to the burningMatters array
        this.burningMatters = res.data.data.burning_matters;
        // Check if the pagination should be activated
        (this.burningMatters.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false;        
        // Stop the loader
        this.overviewDataLoaded = true;      
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getBurningMatterDetails(bmQuestionIdExternal) {
      axios.get(process.env.VUE_APP_API_URL + '/v1/measure/burning_matter/' + bmQuestionIdExternal)
      .then(res => {
        let updatedBurningMatter = res.data.data;
        // Check if table refs are available
        if(this.$refs !== undefined && this.$refs.burningMatterTable !== undefined ) {
          let burningMatterIndex = this.$refs.burningMatterTable.data.findIndex(x => x.bm_question_id_external == updatedBurningMatter.bm_question_id_external);

          this.$refs.burningMatterTable.data[burningMatterIndex].question = updatedBurningMatter.question;
          this.$refs.burningMatterTable.data[burningMatterIndex].subtopic = updatedBurningMatter.subtopic;
          this.$refs.burningMatterTable.data[burningMatterIndex].min_age = updatedBurningMatter.min_age;
          this.$refs.burningMatterTable.data[burningMatterIndex].max_age = updatedBurningMatter.max_age;
          this.$refs.burningMatterTable.data[burningMatterIndex].gender = updatedBurningMatter.gender;
          this.$refs.burningMatterTable.data[burningMatterIndex].start_date = updatedBurningMatter.start_date;
          this.$refs.burningMatterTable.data[burningMatterIndex].end_date = updatedBurningMatter.end_date;
          this.$refs.burningMatterTable.data[burningMatterIndex].teams = updatedBurningMatter.teams;
        }
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    showBurningMatterInsights(id) {
      this.$router.push({path: `/insights/measure/burning-matter/${id.toString()}`});
    },   
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    },
    onPageChange(page) {
      // Set the burningMatterStartIndex value
      (page > 1) ? this.burningMatterStartIndex = (page - 1)*10 : this.burningMatterStartIndex = 0;
      // Get the Burning Matters
      this.getBurningMatters();
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }    
  },
  mounted: function() {
    this.getPlatformPermissions();
    this.getBurningMatters();

    this.$bus.$on('update_burning_matters', () => {
      this.getBurningMatters();
    });

    this.$bus.$on('update_burning_matter_details', (bmQuestionIdExternal) => {
      this.getBurningMatterDetails(bmQuestionIdExternal);
    });   
  },
  beforeDestroy() {
    this.$bus.$off('update_burning_matters');
    this.$bus.$off('update_burning_matter_details');
  }
}
</script>